<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Edit Announcement</h1>
                <hr>
                <div class="row">
                    <div class="col-12 mb-5 p-auto" v-if="!loadData">
                        <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3" v-if="dataExist">
                            <form @submit.prevent="patchAnnouncement" class="row p-2">
                                <div class="col-xl-6 form-group mb-3">
                                    <label for="">Announcement Title</label>
                                    <input v-model="form.title" type="text" required class="form-control text-grey-900 font-xsss fw-600">                        
                                </div>
                                <div class="col-xl-6 form-group mb-3">
                                    <label for="">Status Comment</label>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" @change="checkSwitch" v-model="form.comment_status" class="custom-control-input dark-mode-switch" id="darkmodeswitch">
                                        <label class="custom-control-label bg-current" for="darkmodeswitch"></label>
                                    </div>
                                </div>
                                <div class="col-xl-12 form-group mb-3">
                                    <label for="">Message</label>
                                    <vue-editor v-model="form.message" />
                                </div>
                                <div class="col-xl-12 form-group text-right my-4">
                                    <router-link :to="{name: 'AnnouncementCoursesAdmin',params: {idCourse: paramsId}}" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                    <input type="Submit" class="btn btn-warning" value="Submit">
                                </div>
                            </form>
                        </div>
                        <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3" v-if="dataNotExist">
                            <img :src="'/images/empty.png'" alt="" width="300">
                            <h1 class="text-muted">Data don't exist</h1>
                        </div>
                    </div>
                    <div class="col-12 text-center" v-else>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: "AnouncementsCreate",
    data(){
        return {
            paramsId: this.$route.params.idCourse,
            announcementId: this.$route.params.idAnnouncement,
            dataExist: false,
            dataNotExist: false,
            loadData: true,
            form: {
                title: '',
                comment_status: true,
                message: ''
            },
            validationErrors: '',
        }
    },
    created() {
        this.getAnnouncements()
    },
    methods:{
        async getAnnouncements(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/announcement?announcement_id=${this.announcementId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.loadData = false
                if (res.data == null) {
                    this.dataNotExist = true
                } else {
                    this.dataExist = true
                    this.form = res.data
                }
            }).catch((err) => {
                this.loadData = false
                if (err.response.status) {
                    this.dataNotExist = true
                }
            })
        },
        async patchAnnouncement() {
            var data = {
                id: this.announcementId,
                title : this.form.title,
                message : this.form.message,
                comment_status : this.form.comment_status,
                course_id : this.paramsId,
                _method: 'patch'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/announcement`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Announcement!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'AnnouncementCoursesAdmin' })
            }).catch((err) => {
                if (err.response.status == 422){
                    this.validationErrors = Object.values(err.response.data.errors);
                    this.$swal({
                        toast: true,
                        title: 'Failed Create New Announcement !',
                        text: this.validationErrors[0][0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        checkSwitch() {
            this.form.status !== this.form.status
        },
    }
}
</script>
